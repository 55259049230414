import React from 'react';
import { useTranslation } from 'react-i18next'; // Import translation hook
import './WhyEnergy.css'; // Import HomePage specific styles

function WhyEnergy() {
  const { t } = useTranslation(); // Initialize translation hook

  return (
    <div className="WhyEnergy_home_container">
      <div className="WhyEnergyhome">
        <div className="HeaderWhyEnergy">
          <div className="Header2 TextBlue">
            {t('WhyEnergyEfficiencyMatters')}
          </div>
          <div className="body2 alignbodytext2">
            {t('WhyEnergyEfficiencyDescription')}
          </div>
        </div>

       
        <div className="WhyEnergy_3inputs11">
          <div className="WhyEnergy_1">
            <div className="WhyEnergyImage">
              <img src="icon1.svg" alt="Logo" className="header-image" />
            </div>
            <div className="Header3 alignbodytextwidth">
            {t('WhyEnergyPoint1Title')}
            </div>
            <div className="body3 alignbodytext TextGray3">
            {t('WhyEnergyPoint1Description')}
            </div>
          </div>
        </div>

        <div className="WhyEnergy_3inputs12">
          <div className="WhyEnergy_1">

            <div className="WhyEnergyImage">
              <img src="icon2.svg" alt="Logo" className="header-image" />
            </div>
            <div className="Header3 alignbodytextwidth">
            {t('WhyEnergyPoint2Title')}
            </div>
            <div className="body3 alignbodytext TextGray3">
            {t('WhyEnergyPoint2Description')}
            </div>

          </div>
        </div>

        <div className="WhyEnergy_3inputs13">
          <div className="WhyEnergy_1">

            <div className="WhyEnergyImage">
              <img src="icon3.svg" alt="Logo" className="header-image" />
            </div>
            <div className="Header3 alignbodytextwidth">
            {t('WhyEnergyPoint3Title')}
            </div>
            <div className="body3 alignbodytext TextGray3">
            {t('WhyEnergyPoint3Description')}
            </div>

          </div>
        </div>

        <div className="WhyEnergy_3inputs21">
          <div className="WhyEnergy_1">

            <div className="WhyEnergyImage">
              <img src="icon4.svg" alt="Logo" className="header-image" />
            </div>
            <div className="Header3 alignbodytextwidth">
            {t('WhyEnergyPoint4Title')}
            </div>
            <div className="body3 alignbodytext TextGray3">
            {t('WhyEnergyPoint4Description')}
            </div>

          </div>
        </div>

        <div className="WhyEnergy_3inputs22">
          <div className="WhyEnergy_1">

            <div className="WhyEnergyImage">
              <img src="icon5.svg" alt="Logo" className="header-image" />
            </div>
            <div className="Header3 alignbodytextwidth">
            {t('WhyEnergyPoint5Title')}
            </div>
            <div className="body3 alignbodytext TextGray3">
            {t('WhyEnergyPoint5Description')}
            </div>

          </div>
        </div>

        <div className="WhyEnergy_3inputs23">
          <div className="WhyEnergy_1">

            <div className="WhyEnergyImage">
              <img src="icon6.svg" alt="Logo" className="header-image" />
            </div>
            <div className="Header3 alignbodytextwidth">
            {t('WhyEnergyPoint6Title')}
            </div>
            <div className="body3 alignbodytext TextGray3">
            {t('WhyEnergyPoint6Description')}
            </div>

          </div>
        </div>



      </div>
    </div>
  );
}

export default WhyEnergy;
