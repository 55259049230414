import React from 'react';

import { HelmetProvider } from "react-helmet-async";
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './components/HomePage';

function App() {
  return (
    <HelmetProvider>
    <div className='container'>
    <div className="App">
      <Navbar />
      <HomePage />
      <Footer />
    </div>
    </div>
    </HelmetProvider>
  );
}

export default App;

