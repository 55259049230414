import React from 'react';
import './HomePage.css';  // Import HomePage specific styles
import { useTranslation } from 'react-i18next'; // Import translation hook
import Hero from './home/Hero';
import WhyEnergy from './home/WhyEnergy';
import HomeFeature from './home/HomeFeature';
import HomeHowItWork from './home/HomeHowItWork';

import Table1 from './home/Table1';

import Table2 from './home/Table2';
import ContactForm from './home/ContactForm';

import HomeKeyVerticals from './home/HomeKeyVerticals';
import Slider from './home/Slider';


function HomePage() {
  const { t } = useTranslation(); // Initialize translation hook
  return (
    <div>
 
       <Hero 
        headerText={t('BuildingSustainableFuture')}
        bodyText={t('IntroducingEnergySolution')}
       />
       
       <Slider/>
       <WhyEnergy />
       <HomeHowItWork />    
       
       <Table1 />  
       <Table2 />   
       <HomeFeature />
       <HomeKeyVerticals/>
       {/* <ContactForm/> */}

    
    </div>
  );
}

export default HomePage;