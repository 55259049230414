import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';


import { useTranslation } from 'react-i18next';
import i18n from '../i18n'; // Import i18n configuration
// import { FaGlobe } from 'react-icons/fa'; // Import globe icon

function Navbar() {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  
  const { t } = useTranslation(); // Translation hook


  const handleContactClick = () => {
    const email = 'productsmarketing@neilsoft.com';
    const subject = encodeURIComponent('Inquiry from PowerOptimus Website');
    const mailtoLink = `mailto:${email}?subject=${subject}`;
    window.location.href = mailtoLink;
  };


  const [language, setLanguage] = useState('en'); // Default language set to English


  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  const changeLanguage = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  const scrollToContact = () => {
    const contactSection = document.getElementById("contact-section");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToHero = () => {
    const heroSection = document.getElementById("hero-section");
    if (heroSection) {
      heroSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  

  return (
<div className="navbar-container">
  <nav className="navbar">
    {/* Logo Section */}
    <div className="navbar-brand">
    <img
  src="/app-icon.png"
  alt="Logo"
  className="navbar-logo"
  onClick={scrollToHero}
  style={{ cursor: "pointer" }} // Make it clear that it's clickable
/>
    </div>
    <div className="navbarRightSide">
      <button className="know-more-button Header3Bold" onClick={handleContactClick}>  {t('ContactUs')} </button>
    </div>
  </nav>
</div>

  );
}

export default Navbar;
